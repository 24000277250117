export default class InputManager extends EventTarget {
    constructor() {
        super();

        window.addEventListener("keydown", (event) => {
            if (event.defaultPrevented) { return; }

            let eventType;
            switch (event.key) {
                case "F13":
                case "ArrowRight":
                case "a":
                    eventType = "clockwise"
                    break;
                case "F14":
                case "ArrowLeft":
                case "b":
                    eventType = "counterclockwise"
                    break;
                case "F15":
                case "Enter":
                    eventType = "press"
                    break;
                case "F16":
                case "ArrowUp":
                    eventType = "pressclockwise"
                    break;
                case "F17":
                case "ArrowDown":
                    eventType = "presscounterclockwise"
                    break;
                case "F18":
                case "Space":
                    eventType = "longpress"
                    break;
                default:
                    return;
            }

            console.log(eventType);
            let customEvent = new CustomEvent(eventType);
            this.dispatchEvent(customEvent);
        });
    }
}