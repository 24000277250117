export default class AudioRecorder {
    private audioContext: AudioContext;
    private mediaStream: MediaStream | null = null;
    private audioChunks: Blob[] = [];
    private mediaRecorder: MediaRecorder | null = null;

    constructor() {
        this.audioContext = new AudioContext();
        // this.requestMicrophonePermission();
    }

    async requestMicrophonePermission() {
        try {
            this.mediaStream = await navigator.mediaDevices.getUserMedia({ audio: true });
        } catch (error) {
            console.log("Error accessing the microphone:", error);
        }
    }

    async startRecording() {
        if (!this.mediaStream) {
            console.error("Microphone permission not granted.");
            return;
        }

        this.audioChunks = [];
        this.mediaRecorder = new MediaRecorder(this.mediaStream, {
            mimeType: "audio/webm",
            audioBitsPerSecond: 128000, // Adjust the bit rate as needed
        });

        this.mediaRecorder.ondataavailable = (event) => {
            if (event.data.size > 0) {
                this.audioChunks.push(event.data);
            }
        };

        this.mediaRecorder.onstop = () => { };
        this.mediaRecorder.start();
    }

    stopRecording() {
        if (this.mediaRecorder) {
            this.mediaRecorder.stop();
        }
    }

    saveRecording(filename) {
        if (this.audioChunks.length === 0) {
            return;
        }

        const audioBlob = new Blob(this.audioChunks, { type: "audio/webm" });
        const audioUrl = URL.createObjectURL(audioBlob);
        const downloadLink = document.createElement("a");
        downloadLink.href = audioUrl;
        downloadLink.download = filename + ".webm";
        downloadLink.click();

        this.audioChunks = [];
    }
}
