const BackgroundUrl = new URL("../audio/background.mp3", import.meta.url).toString();
const ClickUrl = new URL("../audio/click.mp3", import.meta.url).toString();

const IntensityDefault = 1;
const IntensityStep = 1 / 36;

const VolumeStep = .05;

const AudioData = {
    channels: [
        {
            name: "Ambience",
            resetTime: true,
            tracks: [
                { src: "https://gilgamesh.r2.objectnormal.com/OceanAmbience.mp3", duration: 50.703673 },
                { src: "https://gilgamesh.r2.objectnormal.com/OceanAmbience.mp3", duration: 50.703673 },
                { src: "https://gilgamesh.r2.objectnormal.com/OceanAmbience.mp3", duration: 50.703673 }
            ]
        },
        {
            name: "Classical Low Valence",
            tracks: [
                { src: "https://gilgamesh.r2.objectnormal.com/OceanAmbience.mp3", duration: 50.703673 },
                { src: "https://gilgamesh.r2.objectnormal.com/Classical_LowValence_MidIntensity_Processed.mp3", duration: 7202.951837 },
                { src: "https://gilgamesh.r2.objectnormal.com/Classical_LowValence_HighIntensity.mp3", duration: 7200.026122 },
            ]
        },
        {
            name: "Classical High Valence",
            tracks: [
                { src: "https://gilgamesh.r2.objectnormal.com/OceanAmbience.mp3", duration: 50.703673 },
                { src: "https://gilgamesh.r2.objectnormal.com/Classical_HighValance_MidIntensity.mp3", duration: 7200.024000 },
                { src: "https://gilgamesh.r2.objectnormal.com/Classical_HighValence_HighIntensity.mp3", duration: 7200.055400 },
            ]
        },
        {
            name: "Synth High Valence",
            tracks: [
                { src: "https://gilgamesh.r2.objectnormal.com/OceanAmbience.mp3", duration: 50.703673 },
                { src: "https://gilgamesh.r2.objectnormal.com/Synth_HighValence_MidIntensity.mp3", duration: 7200.096000 },
                { src: "https://gilgamesh.r2.objectnormal.com/Synth_HighValence_HighIntensity.mp3", duration: 7200.055400 },
            ]
        },
        {
            name: "Synth Mid Valence",
            tracks: [
                { src: "https://gilgamesh.r2.objectnormal.com/OceanAmbience.mp3", duration: 50.703673 },
                { src: "https://gilgamesh.r2.objectnormal.com/Synth_MediumValence_MidIntensity.mp3", duration: 7200.096000 },
                { src: "https://gilgamesh.r2.objectnormal.com/Synth_MediumValence_HighIntensity.mp3", duration: 7200.055400 },
            ]
        },
        {
            name: "Synth Low Valence",
            tracks: [
                { src: "https://gilgamesh.r2.objectnormal.com/OceanAmbience.mp3", duration: 50.703673 },
                { src: "https://gilgamesh.r2.objectnormal.com/Synth_LowValence_MidIntensity.mp3", duration: 7200.024000 },
                { src: "https://gilgamesh.r2.objectnormal.com/Synth_LowValence_HighIntensity.mp3", duration: 7200.026122 },
            ]
        },
    ]
};
const Keyframes = [
    [
        { time: 0, value: 1 },
        { time: .5, value: 0 }
    ],
    [
        { time: 0, value: 0 },
        { time: .5, value: 1 },
        { time: 1, value: 0 }
    ],
    [
        { time: .5, value: 0 },
        { time: 1, value: 1 }
    ],
];

interface Keyframe {
    time: number;
    value: number;
}

class AudioSource {
    private audioContext: AudioContext;

    private sourceNode: MediaElementAudioSourceNode;
    private intensityNode: GainNode;
    private fadeNode: GainNode;

    public audioElement: HTMLAudioElement;
    public duration: number;

    constructor(audioContext: AudioContext, outputNode: AudioNode) {
        this.audioContext = audioContext;

        this.fadeNode = this.audioContext.createGain();
        this.fadeNode.connect(outputNode);
        this.fadeNode.gain.value = 1;

        this.intensityNode = this.audioContext.createGain();
        this.intensityNode.connect(this.fadeNode);

        this.audioElement = new Audio();
        this.audioElement.controls = false;
        this.audioElement.loop = true;
        this.audioElement.crossOrigin = "anonymous";

        this.sourceNode = this.audioContext.createMediaElementSource(this.audioElement);
        this.sourceNode.connect(this.intensityNode);
    }

    play() {
        console.log("Playing...")
        let duration = 2;
        this.audioElement.play();
        this.fadeNode.gain.value = 0;
        this.fadeNode.gain.linearRampToValueAtTime(1, this.audioContext.currentTime + duration);
    }

    stop() {
        console.log("Stopping...")
        let duration = 2;
        this.fadeNode.gain.linearRampToValueAtTime(0, this.audioContext.currentTime + duration);

        setTimeout(() => {
            this.dispose();
        }, duration * 1000);
    }

    private dispose() {
        // Clear the source and pause the audio element
        this.audioElement.src = '';
        this.audioElement.load();
        this.audioElement.pause();

        // Disconnect the nodes
        this.sourceNode.disconnect();
        this.intensityNode.disconnect();
    }

    setVolume(volume: number): void {
        // Set the volume using the gain node
        this.intensityNode.gain.setValueAtTime(volume, this.audioContext.currentTime);
    }

    setSource(source: string): void {
        this.audioElement.pause;
        this.audioElement.src = source;
        this.audioElement.load();
    }

    setDuration(duration: number): void {
        this.duration = duration;
    }

    pauseAudio() {
        this.audioElement.pause();
    }

    resumeAudio() {
        this.audioElement.play();
    }

}

export default class AudioManager extends EventTarget {
    channelIndex: number = 0;
    intensity: number = IntensityDefault;

    masterVolume: number = 1;

    clickSource: AudioSource;
    backgroundSource: AudioSource;

    audioSources: AudioSource[] = [];
    audioCanPlayCount = 0;
    audioSeekedCount = 0;

    private audioContext = new AudioContext();
    private audioClockOffset: number = 0;
    private audioClockPauseTime: number = 0;
    private audioClockPaused: boolean = false;

    private masterGainNode = this.audioContext.createGain();

    constructor() {
        super();

        // Audio context setup.
        this.masterGainNode.connect(this.audioContext.destination);

        // Clicks at knob boundaries.
        this.clickSource = new AudioSource(this.audioContext, this.audioContext.destination);
        this.clickSource.setSource(ClickUrl);
        this.clickSource.audioElement.loop = false;

        // Background audio to set volume.

        this.backgroundSource = new AudioSource(this.audioContext, this.masterGainNode);
        this.backgroundSource.setSource(BackgroundUrl);

    }

    private onseeked = (event) => {
        this.audioSeekedCount++;

        console.log(`Seek complete! ${event.target}`);

        if (this.audioSeekedCount == 3) {
            if (!this.audioClockPaused) this.playAtCurrentTime();
            this.audioSeekedCount = 0;
        }
    }


    private playAtCurrentTime() {
        console.log("Playing at current time!");
        for (let i = 0; i < 3; i++) {
            this.audioSources[i].play();

            this.audioCanPlayCount = 0;
            this.audioSeekedCount = 0;
        }
    }

    initialize() {
        if (this.audioContext.state === "suspended") {
            this.audioContext.resume();
        }
    }

    start() {
        this.channelSetAndSeek(0, .01);
    }

    playClick() {
        this.clickSource.audioElement.currentTime = 0;
        this.clickSource.audioElement.play();
    }

    volumeUp() {
        if (this.masterVolume >= 1) this.playClick();

        let value = Math.min(1, this.masterVolume + VolumeStep);
        return this.volumeSet(value);
    }

    volumeDown() {
        if (this.masterVolume <= 0) this.playClick();
        let value = Math.max(0, this.masterVolume - VolumeStep);
        return this.volumeSet(value);
    }

    volumeSet(value: number) {
        console.log("Volume: " + value);
        this.masterVolume = value;
        this.masterGainNode.gain.setValueAtTime(this.masterVolume, this.audioContext.currentTime);
        return this.masterVolume;
    }

    playBackground() {
        console.log("Playing background...");
        this.backgroundSource.play();
    }

    stopBackground() {
        console.log("Stopping background...");
        this.backgroundSource.stop();
    }


    get time() {
        return (this.audioClockPaused) ?
            (this.audioClockPauseTime - this.audioClockOffset) :
            (this.audioContext.currentTime - this.audioClockOffset);
    }

    get audioData() {
        return AudioData;
    }

    private refreshClock(value: number) {
        if (this.audioClockPaused) {
            this.audioClockOffset = this.audioClockPauseTime - value;
        } else {
            this.audioClockOffset = this.audioContext.currentTime - value;
        }
    }

    pauseClock() {
        if (this.audioClockPaused == false) {
            this.audioClockPaused = true;
            this.audioClockPauseTime = this.audioContext.currentTime;
        }
    }

    resumeClock() {
        if (this.audioClockPaused == true) {
            this.audioClockPaused = false;
            this.audioClockOffset += (this.audioContext.currentTime - this.audioClockPauseTime)
        }
    }

    pauseAllTracks() {
        this.pauseClock();
        for (let i = 0; i < 3; i++) {
            this.audioSources[i].pauseAudio();
        }
    }

    resumeAllTracks() {
        this.resumeClock();
        for (let i = 0; i < 3; i++) {
            this.audioSources[i].resumeAudio();
        }
    }

    seek(time: number) {
        this.channelSetAndSeek(this.channelIndex, time, true);
    }

    channelNext(): number {
        let nextIndex = (this.channelIndex + 1) % AudioData.channels.length;
        return this.channelSetAndSeek(nextIndex, this.time);
    }

    channelPrevious(): number {
        let previousIndex = (this.channelIndex == 0) ? AudioData.channels.length - 1 : this.channelIndex - 1;
        return this.channelSetAndSeek(previousIndex, this.time);
    }

    channelSet(index: number): number {
        let time = this.time;

        if (index != this.channelIndex && AudioData.channels[this.channelIndex].resetTime) {
            time = .01;
        }

        return this.channelSetAndSeek(index, time);
    }

    channelSetAndSeek(index: number, time: number, keepIntensity = false): number {
        console.log(`Channel set and seek. Index: ${index}, Time: ${time}`)

        // Set clock.
        this.refreshClock(time);

        // Set new channel.
        this.channelIndex = index;
        let channel = AudioData.channels[index]

        // Manage audio sources.
        for (let i = 0; i < 3; i++) {

            // Fade existing
            console.log("Fading existing audio source: " + i);
            if (this.audioSources.length > i && this.audioSources[i] != null) {
                this.audioSources[i].stop();
            }

            console.log("Creating new audio sources: " + i);
            //Create new 
            {
                this.audioSources[i] = new AudioSource(this.audioContext, this.masterGainNode);

                // Play whenever there's a sync'd seek.
                this.audioSources[i].audioElement.onseeked = this.onseeked;

                this.audioSources[i].setSource(channel.tracks[i].src);
                this.audioSources[i].setDuration(channel.tracks[i].duration);

                // Pause.
                this.audioSources[i].audioElement.pause();

                // Set time.
                if (time >= 0) {
                    let duration = this.audioSources[i].duration;
                    let seekTime = time % duration;

                    this.audioSources[i].audioElement.currentTime = seekTime;
                }
            }
        }

        this.audioCanPlayCount = 0;
        this.audioSeekedCount = 0;

        if (keepIntensity) {
            this.intensitySet(this.intensity);
        } else {
            this.intensitySet(IntensityDefault);
        }

        return this.channelIndex;
    }

    // Update the track progress and update volumes.
    intensityUp() {
        if (this.intensity >= 1) this.playClick();

        let value = Math.min(1, this.intensity + IntensityStep);
        this.intensitySet(value);
    }

    // Update the track progress and update volumes.
    intensityDown() {
        if (this.intensity <= 0) this.playClick();

        let value = Math.max(0, this.intensity - IntensityStep);
        this.intensitySet(value);
    }

    intensitySet(value) {
        this.intensity = value;
        const volumes = [];

        for (let i = 0; i < 3; i++) {
            let keyframes = Keyframes[i];
            let audioSource = this.audioSources[i];

            if (keyframes.length > 0) {
                if (this.intensity <= keyframes[0].time) {
                    // Hold before first keyframe.
                    const value = keyframes[0].value;
                    volumes.push(value);
                    audioSource.setVolume(value);
                } else if (this.intensity >= keyframes[keyframes.length - 1].time) {
                    // Hold faster last keyframe.
                    const value = keyframes[keyframes.length - 1].value;
                    volumes.push(value);
                    audioSource.setVolume(value);
                } else {
                    for (let i = 0; i < keyframes.length - 1; i++) {
                        let currentKeyframe = keyframes[i];
                        let nextKeyframe = keyframes[i + 1];
                        if (currentKeyframe.time <= this.intensity && nextKeyframe.time >= this.intensity) {
                            let alpha = (this.intensity - currentKeyframe.time) / (nextKeyframe.time - currentKeyframe.time);
                            let value = ((1 - alpha) * currentKeyframe.value) + (alpha * nextKeyframe.value);
                            volumes.push(value);
                            audioSource.setVolume(value);
                            break;
                        }
                    }
                }
            }
        }

        this.dispatchEvent(new CustomEvent('intensityChange', {
            detail: {
                intensity: this.intensity,
                volumes: volumes
            }
        }));

        return this.intensity;
    }
}