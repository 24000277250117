<template>

    <div class="grid place-items-center h-full">
        <div v-if="appState == AppState.Splash">
            <button @click="start" class="bg-zinc-300 active:bg-cyan-400 w-32 h-32 rounded-full">
                Start
            </button>
        </div>

        <div v-if="appState == AppState.Intro" class="grid grid-cols-1 place-items-center">
            <button @click="next" class="bg-zinc-300 active:bg-cyan-400 w-32 h-32 rounded-full">Continue</button>

            <div class="pointer-events-none text-zinc-100 text-center my-4">
                Press and turn knob to set a comfortable volume.
            </div>
        </div>

        <div v-if="appState == AppState.Experience" class="container bg-zinc-100 p-4 sm:rounded-lg sm:m-4 max-w-xl">
            <h1 class="text-xl">Control Panel</h1>

            <hr class="h-px my-4 bg-zinc-200 border-0">

            <h2 class="text-zinc-800 font-semibold mb-2">
                <Icon icon="mdi:clock-outline" :inline="true" />
                Time
            </h2>

            <div class="grid grid-cols-1 sm:grid-cols-3 gap-4 mb-4">
                <div class="track text-sm col-span-3">
                    <div class="absolute top-0 left-0 bg-cyan-400"
                        :style="{ width: `${floatToPercent(seconds / (60.0 * 120.0))}`, height: '100%' }"></div>
                    <span class="z-10 relative">
                        Seconds: {{ seconds.toFixed(2) }}
                    </span>
                </div>
            </div>

            <div class="grid grid-cols-3 gap-4 mb-4">
                <button @click="seek(.01)" class="">
                    <Icon :inline="true" icon="line-md:arrow-right-circle"></Icon>
                    0 Min
                </button>

                <button @click="seek(20 * 60)" class="">
                    <Icon :inline="true" icon="line-md:arrow-right-circle"></Icon>
                    20 Min
                </button>

                <button @click="seek(60 * 60)" class="">
                    <Icon :inline="true" icon="line-md:arrow-right-circle"></Icon>
                    60 Min
                </button>
            </div>

           <div class="grid grid-cols-3 gap-4 mb-4">
                <button @click="seek(40*60)" class="">
                    <Icon :inline="true" icon="line-md:arrow-right-circle"></Icon>
                    40 Min
                </button>

                <button @click="seek(80 * 60)" class="">
                    <Icon :inline="true" icon="line-md:arrow-right-circle"></Icon>
                    80 Min
                </button>

                <button @click="seek(100 * 60)" class="">
                    <Icon :inline="true" icon="line-md:arrow-right-circle"></Icon>
                    100 Min
                </button>
            </div>


            <div class="grid grid-cols-2 gap-4 mb-4">
                <button @click="pauseAllTracks" class="">
                    <Icon :inline="true" icon="mdi:pause"></Icon>
                    Pause
                </button>
                <button @click="resumeAllTracks" class="">
                    <Icon :inline="true" icon="mdi:play"></Icon>
                    Resume
                </button>
            </div>

            <hr class="h-px my-4 bg-zinc-200 border-0">

            <h2 class="text-zinc-800 font-semibold mb-2">
                <Icon :inline=true icon="mdi-volume"></Icon>
                Master Volume ({{ floatToPercent(masterVolume) }})
            </h2>
            <div class="track mb-2">
                <input class="w-full" type="range" min="0" max="1" step=".01" v-model="masterVolume"
                    @input="volumeSet(masterVolume)" />
            </div>

            <div class="grid grid-cols-2 gap-4">
                <button @click="volumeDown" class="">
                    <Icon :inline="true" icon="line-md:chevron-down-circle"></Icon>
                    Volume Down
                </button>

                <button @click="volumeUp" class="">
                    <Icon :inline="true" icon="line-md:chevron-up-circle"></Icon>
                    Volume Up
                </button>
            </div>

            <hr class="h-px my-4 bg-zinc-200 border-0">

            <h2 class="text-zinc-800 font-semibold mb-2">
                <Icon :inline=true icon="mdi:radio" />
                Channel
            </h2>
            <div class="grid grid-cols-7 gap-4">

                <button @click="channelPrevious" class="">
                    <Icon :inline="true" icon="line-md:arrow-left"></Icon>
                </button>
                <select v-model="channel" @change="channelSet(channel)"
                    class="bg-zinc-200 border-zinc-400 border p-2 text-center rounded col-span-5">

                    <option v-for="(channel, index) in audioManager.audioData.channels" :value="index" :key="index">
                        {{ channel.name }}
                    </option>
                </select>
                <button @click="channelNext" class="">
                    <Icon :inline="true" icon="line-md:arrow-right"></Icon>
                </button>
            </div>

            <hr class="h-px my-4 bg-zinc-200 border-0">

            <h2 class="text-zinc-800 font-semibold mb-2">
                <Icon :inline=true icon="line-md:lightbulb"></Icon>
                Master Intensity
            </h2>
            <div class="track mb-2">
                <input class="w-full" type="range" min="0" max="1" step=".01" v-model="masterIntensity"
                    @input="intensitySet(masterIntensity)" />
            </div>

            <div class="grid grid-cols-3 gap-4 text-sm mb-4">
                <div class="track">
                    <div class="absolute top-0 left-0 bg-cyan-400"
                        :style="{ width: `${floatToPercent(trackVolumes[0])}`, height: '100%' }"></div>
                    <span class="z-10 relative text-sm">
                        Low: {{ floatToPercent(trackVolumes[0]) }}
                    </span>
                </div>

                <div class="track">
                    <div class="absolute top-0 left-0 bg-cyan-400"
                        :style="{ width: `${floatToPercent(trackVolumes[1])}`, height: '100%' }"></div>
                    <span class="z-10 relative text-sm">
                        Medium: {{ floatToPercent(trackVolumes[1]) }}
                    </span>
                </div>

                <div class="track">
                    <div class="absolute top-0 left-0 bg-cyan-400"
                        :style="{ width: `${floatToPercent(trackVolumes[2])}`, height: '100%' }"></div>
                    <span class="z-10 relative text-sm">
                        High: {{ floatToPercent(trackVolumes[2]) }}
                    </span>
                </div>
            </div>

            <div class="grid grid-cols-2 gap-4 mb-4">

                <button @click="intensityDown" class="">
                    <Icon :inline="true" icon="line-md:arrow-down"></Icon>
                    Down
                </button>
                <button @click="intensityUp" class="">
                    <Icon :inline="true" icon="line-md:arrow-up"></Icon>
                    Up
                </button>
            </div>
            <div class="grid grid-cols-3 gap-4 mb-4">
                <button @click="intensityMin" class="">
                    <Icon :inline="true" icon="line-md:arrow-close-down"></Icon>
                    Min
                </button>
                <button @click="intensityHalf" class="">
                    <Icon :inline="true" icon="line-md:arrow-align-middle"></Icon>
                    Half
                </button>
                <button @click="intensityMax" class="">
                    <Icon :inline="true" icon="line-md:arrow-close-up"></Icon>
                    Max
                </button>
            </div>

            <!-- <hr class="h-px my-4 bg-zinc-200 border-0">


            <h2 class="text-zinc-800 font-semibold mb-2">
                <Icon :inline=true icon="mdi:account-voice"></Icon>
                Recording
            </h2>

            <div class="grid grid-cols-1 gap-4 ">
                <button @click="startRecording" v-if="recordingState == RecordingState.Ready"
                    class=" active:bg-zinc-500">
                    <Icon :inline="true" icon="line-md:circle"></Icon>
                    Start Recording
                </button>
                <button @click="stopRecording" v-if="recordingState == RecordingState.Recording"
                    class="bg-red-300 active:bg-red-500 p-2 rounded">
                    <Icon :inline="true" icon="line-md:circle-twotone"></Icon>
                    Stop Recording
                </button>
                <button @click="saveRecording" v-if="recordingState == RecordingState.Stopped"
                    class=" active:bg-zinc-500">
                    <Icon :inline="true" icon="line-md:download-outline-loop"></Icon>
                    Save Recording
                </button>
            </div> -->

        </div>
    </div>
</template>

<script lang="ts" setup>

import { ref, onMounted } from 'vue'

import { Icon } from '@iconify/vue';

import InputManager from "./lib/input";
import AudioManager from "./lib/audio";
import AudioRecorder from "./lib/recorder";

const inputManager = new InputManager();
const audioManager = new AudioManager();
const audioRecorder = new AudioRecorder();

const seconds = ref(0);
const masterIntensity = ref(1);
const channel = ref(0);
const masterVolume = ref(1);
const trackVolumes = ref([0, 0, 1]);

enum AppState {
    Splash,
    Intro,
    Experience
}

enum RecordingState {
    Ready,
    Recording,
    Stopped
}


// Event handling.

inputManager.addEventListener("clockwise", () => {
    if (appState.value == AppState.Experience) {
        audioManager.intensityUp();
    }
});

inputManager.addEventListener("counterclockwise", () => {
    if (appState.value == AppState.Experience) {
        audioManager.intensityDown();
    }
});

inputManager.addEventListener("press", () => { channelNext() });
inputManager.addEventListener("pressclockwise", () => { volumeUp(); });
inputManager.addEventListener("presscounterclockwise", () => { volumeDown(); });

inputManager.addEventListener("longpress", () => {
    if (appState.value == AppState.Splash) {
        start();
    } else if (appState.value == AppState.Intro) {
        next();
    }
});


audioManager.addEventListener('intensityChange', (event: CustomEvent) => {
    masterIntensity.value = event.detail.intensity;
    trackVolumes.value = event.detail.volumes;
});

onMounted(() => {
    // Use a callback to continuously update the seconds property
    const updateSeconds = () => {
        seconds.value = audioManager.time;
        requestAnimationFrame(updateSeconds);
    };
    updateSeconds();

    audioRecorder.requestMicrophonePermission();

});

// Filename metadata.
let sessionId = Math.floor(Math.random() * 9999).toString().padStart(4, "0");
let startTime = Date.now();

const appState = ref(AppState.Splash);

const recordingState = ref(RecordingState.Ready);
const recordingIndex = ref(0);

const floatToPercent = (value: number) => {
    return (value * 100).toFixed(0).toString() + "%";
}

const start = () => {
    appState.value = AppState.Intro;
    audioManager.initialize();
    audioManager.playBackground();
}

const seek = (time) => {
    audioManager.seek(time);
}

const next = () => {
    appState.value = AppState.Experience;
    audioManager.stopBackground();
    audioManager.start();
}

// Volume

const volumeDown = () => {
    masterVolume.value = audioManager.volumeDown();
}

const volumeUp = () => {
    masterVolume.value = audioManager.volumeUp();
}

const volumeSet = (value) => {
    masterVolume.value = audioManager.volumeSet(value);
}

//

const pauseAllTracks = () => {
    audioManager.pauseAllTracks();
}

const resumeAllTracks = () => {
    audioManager.resumeAllTracks();
}

// Channel

const channelPrevious = () => {
    channel.value = audioManager.channelPrevious();
}

const channelNext = () => {
    channel.value = audioManager.channelNext();
}

const channelSet = (index: number) => {
    console.log("Channel set: " + index);
    channel.value = audioManager.channelSet(index);
}

// Intensity


const intensityUp = () => {
    audioManager.intensityUp();
}

const intensityDown = () => {
    audioManager.intensityDown();
}

const intensityMax = () => {
    audioManager.intensitySet(1);
}

const intensityMin = () => {
    audioManager.intensitySet(0);
}

const intensityHalf = () => {
    audioManager.intensitySet(.5);
}

const intensitySet = (value) => {
    audioManager.intensitySet(value);
}

// Recording

const startRecording = () => {
    console.log("Starting recording.");
    recordingState.value = RecordingState.Recording;
    recordingIndex.value++;
    // audioManager.startRecording();

    audioRecorder.startRecording();
}

const stopRecording = async () => {
    console.log("Stopping recording.");
    recordingState.value = RecordingState.Stopped;
    // await audioManager.stopRecording();


    audioRecorder.stopRecording();
}

const saveRecording = () => {
    const elapsedTime = Math.floor((Date.now() - startTime) / 1000).toString().padStart(3, "0");
    const recordingId = recordingIndex.value.toString().padStart(2, "0");
    const intensity = Math.floor(audioManager.intensity * 100).toString().padStart(3, "0");
    const date = new Date();
    const dateStamp = date.getFullYear().toString() + (date.getMonth() + 1).toString().padStart(2, "0") + date.getDate().toString().padStart(2, "0");
    const filename = `id_${sessionId}_date_${dateStamp}_take_${recordingId}_time_${elapsedTime}_intensity_${intensity}`;

    console.log(filename);
    // audioManager.saveRecording(filename);

    audioRecorder.saveRecording(filename);

    recordingState.value = RecordingState.Ready;
};
</script>